:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._mt-t-space--9{margin-top:var(--t-space--9);}
:root ._fd-row{flex-direction:row;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._gap-t-space-1{gap:var(--t-space-1);}
:root ._mt-t-space-3{margin-top:var(--t-space-3);}
:root ._jc-center{justify-content:center;}
:root ._gap-t-space-6{gap:var(--t-space-6);}
:root ._mt-t-space-5{margin-top:var(--t-space-5);}
:root ._mb-t-space-5{margin-bottom:var(--t-space-5);}
:root ._ai-center{align-items:center;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._mt-t-space--1-1448{margin-top:var(--t-space--1--5);}
:root ._btc-borderColor{border-top-color:var(--borderColor);}
:root ._brc-borderColor{border-right-color:var(--borderColor);}
:root ._bbc-borderColor{border-bottom-color:var(--borderColor);}
:root ._blc-borderColor{border-left-color:var(--borderColor);}
:root ._fs-1{flex-shrink:1;}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-0px{border-left-width:0px;}
:root ._fg-1{flex-grow:1;}
:root ._h-0px{height:0px;}
:root ._mah-0px{max-height:0px;}
:root ._mb-t-space-4{margin-bottom:var(--t-space-4);}
:root ._mt-t-space-4{margin-top:var(--t-space-4);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._transform-translateY-1736186894{transform:translateY(-0.5px);}
:root ._gap-t-space-3{gap:var(--t-space-3);}
@media (min-width: 661px){:root:root:root:root:root:root:root:root:root:root ._fd-_gtXs_row{flex-direction:row;}}
@media (min-width: 661px){:root:root:root:root:root:root:root:root:root:root ._jc-_gtXs_space-betwe3241{justify-content:space-between;}}